import axios from "axios";

var baseURL = "";

// Platform API
if (location.href.includes('www.platform.nexlaw.ai')) {
    baseURL = "https://api.platform.nexmind.ai/api/"
}

// Beta API | FE Localhost
else {
    baseURL = "https://app.nexmind.nexodn.com/api/";
}

export default axios.create({ baseURL });