import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const ROUTES = {
  Archieve: [
    {
      path: "/archieve",
      name: "Archieve",
      component: () => import("@/Pages/Archieve/main.vue"),
    },
    {
      path: "/archieve_copilot",
      name: "ArchieveCopilot",
      component: () => import("@/Pages/Archieve/archieve_copilot.vue"),
    },
    {
      path: "/archieve_advice",
      name: "ArchieveAdvice",
      component: () => import("@/Pages/Archieve/archieve_advice.vue"),
    },
    {
      path: "/archieve_summarizer",
      name: "ArchieveSummarizer",
      component: () => import("@/Pages/Archieve/archieve_summarizer.vue"),
    },
    {
      path: "/archieve_research",
      name: "ArchieveResearch",
      component: () => import("@/Pages/Archieve/archieve_research.vue"),
    },
  ],

  Bookmark:[
    {
      path: "/bookmark",
      name: "Bookmark",
      component: () => import("@/Pages/Bookmark/main.vue"),
    },
    {
      path: "/bookmark_copilot",
      name: "BookmarkCopilot",
      component: () => import("@/Pages/Bookmark/bookmark_copilot.vue"),
    },
    {
      path: "/bookmark_advice",
      name: "BookmarkAdvice",
      component: () => import("@/Pages/Bookmark/bookmark_advice.vue"),
    },
    {
      path: "/bookmark_summarizer",
      name: "BookmarkSummarizer",
      component: () => import("@/Pages/Bookmark/bookmark_summarizer.vue"),
    },
    {
      path: "/bookmark_research",
      name: "BookmarkResearch",
      component: () => import("@/Pages/Bookmark/bookmark_research.vue"),
    },
  ],

  ExpertMode: [
    {
      path: "/expert_mode",
      name: "ExpertMode",
      component: () => import("@/Pages/Mode/Nexa/main.vue"),
    },
    {
      path: "/chat_nexa/:id",
      name: "ChatNeXa",
      component: () => import("@/Pages/Mode/LawBot.vue"),
    },
    {
      path: "/uploadfile_result/:id",
      name: "UploadFileResult",
      component: () => import("@/Pages/Mode/Nexa/file_result.vue"),
    },
    {
      path: "/expert_mode/analyze_pleadings",
      name: "AnalyzePleadings",
      component: () => import("@/Pages/Mode/analyze_pleading.vue"),
    },
    {
      path: "/expert_mode/analyze_result",
      name: "AnalyzePleadingResult",
      component: () => import("@/Pages/Mode/analyze_result.vue"),
    },
    // DeepResearch
    {
      path: "/expert_mode/deep_research",
      name: "DeepResearch",
      component: () => import("@/Pages/Mode/DeepResearch/deep_research.vue"),
    },
    {
      path: "/expert_mode/deep_research/:id",
      name: "DeepResearchResult",
      component: () =>
        import("@/Pages/Mode/DeepResearch/deep-search-result.vue"),
    },
    {
      path: "/expert_mode/deep_research/:id/edit",
      name: "DeepResearchEdit",
      component: () => import("@/Pages/Mode/DeepResearch/deep-search-edit.vue"),
    },
    // Build Argument
    {
      path: "/expert_mode/build_argument",
      name: "BuildArgument",
      component: () => import("@/Pages/Mode/BuildArgument/build_argument.vue"),
    },
    {
      path: "/expert_mode/build_argument/:id",
      name: "BuildArgumentResult",
      component: () =>
        import("@/Pages/Mode/BuildArgument/build-argument-result.vue"),
    },
    {
      path: "/expert_mode/build_argument/:id/summary",
      name: "BuildArgumentSummary",
      component: () =>
        import("@/Pages/Mode/BuildArgument/build_argument-summary.vue"),
    },
    // Legal Memo
    {
      path: "/expert_mode/legal_memo",
      name: "LegalMemo",
      component: () => import("@/Pages/Mode/LegalMemo/legal_memo.vue"),
    },
    {
      path: "/expert_mode/legal_memo/:id",
      name: "BuildMemoResult",
      component: () => import("@/Pages/Mode/LegalMemo/legalmemo_result.vue"),
    },
    {
      path: "/expert_mode/legal_memo/:id/summary",
      name: "BuildMemoSummary",
      component: () => import("@/Pages/Mode/LegalMemo/legalmemo_summary.vue"),
    },
    {
      path: "/expert_mode/draft_contract",
      name: "DraftContract",
      component: () => import("@/Pages/Mode/draft_contract.vue"),
    },
    // Document Insight
    {
      path: "/expert_mode/document_insight",
      name: "DocumentInsight",
      component: () =>
        import("@/Pages/Mode/DocumentInsight/document_insight.vue"),
    },
    {
      path: "/expert_mode/document_insight/:id",
      name: "DocumentInsightResult",
      component: () =>
        import("@/Pages/Mode/DocumentInsight/documentinsight_result.vue"),
    },
    {
      path: "/expert_mode/summarize_case",
      name: "SummarizeCase",
      component: () => import("@/Pages/Mode/summarize_case.vue"),
    },
    // Find Precedent
    {
      path: "/expert_mode/find_precedent",
      name: "FindPrecedent",
      component: () => import("@/Pages/Mode/FindPrecedent/find_precedent.vue"),
    },
    {
      path: "/expert_mode/find_precedent/:id",
      name: "FindPrecedentResult",
      component: () =>
        import("@/Pages/Mode/FindPrecedent/findprecedent_result.vue"),
    },
    {
      path: "/expert_mode/find_precedent/:id/detail/:title",
      name: "FindPrecedentDetail",
      component: () =>
        import("@/Pages/Mode/FindPrecedent/findprecedent_detail.vue"),
    },
    // CompareJuri
    {
      path: "/expert_mode/compare_juri",
      name: "CompareJuri",
      component: () => import("@/Pages/Mode/CompareJuri/compare_juri.vue"),
    },
    {
      path: "/expert_mode/compare_juri/:id",
      name: "CompareJuriResult",
      component: () =>
        import("@/Pages/Mode/CompareJuri/compare_juri_result.vue"),
    },
    {
      path: "/expert_mode/contract_diligence",
      name: "ContractDiligence",
      component: () => import("@/Pages/Mode/contract_diligence.vue"),
    },
  ],

  LegalCopilot: [
    {
      path: "/legal_copilot",
      name: "LegalCopilot",
      component: () => import("@/Pages/LegalCopilot/main.vue"),
    },
    {
      path: "/legal_copilot/pre_trial",
      name: "LegalCopilot_PreTrial",
      component: () => import("@/Pages/LegalCopilot/pre_trial.vue"),
    },
    {
      path: "/legal_copilot/caseflow_result/:id",
      name: "LegalCopilot_CaseFlowResult",
      component: () => import("@/Pages/LegalCopilot/caseflow_result.vue"),
    },

    // the following path is not yet connected
    {
      path: "/legal_copilot/campaign_:id",
      name: "",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Copilot/Case_Flow_Result/:id/:category
       * name: LegalExpertSystemPreTrialResult
       * comp: LegalExpertSystem/PreTrialResult.vue
       */
    },
    {
      path: "/legal_copilot/campaign_:id/trial",
      name: "LegalCopilot_Trial",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Copilot/Trial_Input/:id
       * name: LegalExpertSystemFileInput
       * comp: LegalExpertSystem/FileUpload.vue
       */
    },
    {
      path: "/legal_copilot/campaign_:id/new_trial",
      name: "LegalCopilot_NewTrial",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Copilot/New_Trial/:id
       * name: LegalExpertSystemNewTrial
       * comp: LegalExpertSystem/NewTrial.vue
       */
    },
    {
      path: "/legal_copilot/:id/post_trial",
      name: "LegalCopilot_PostTrial",
      component: () => import("@/Pages/LegalCopilot/end_case.vue"),
      /** Original Path
       * path: /Legal_Copilot/Final_Submission/:id
       * name: LegalExpertSystemEndCase
       * comp: LegalExpertSystem/EndCase.vue
       */
    },
  ],

  LegalAdvice: [
    {
      path: "/legal_advice",
      name: "LegalAdvice",
      component: () => import("@/Pages/LegalAdvice/main.vue"),
    },
    // argument
    {
      path: "/legal_advice/argument_result/:id/:category",
      name: "LegalAdvice_ArgumentResult",
      component: () => import("@/Pages/LegalAdvice/argument_result.vue"),
    },
    // memo
    {
      path: "/legal_advice/memo_result/:id/:category",
      name: "LegalAdvice_MemoResult",
      component: () => import("@/Pages/LegalAdvice/caseflow_result.vue"),
    },
    {
      path: "/legal_advice/caseflow_result/summary",
      name: "LegalAdvice_Summary",
      component: () => import("@/Pages/LegalAdvice/summary.vue"),
    },
  ],
  LegalResearch: [
    // Main Page for CaseLawSearch & LegislationSearch
    {
      path: "/legal_research",
      name: "LegalResearch",
      component: () => import("@/Pages/LegalResearch/main.vue"),
    },
    // CaseLawSearch
    // Input fields
    {
      path: "/case_law_search",
      name: "CaseLawSearch",
      component: () => import("@/Pages/LegalResearch/case_law_search/main.vue"),
    },
    // history table
    {
      path: "/case_law_search/history",
      name: "CaseLawSearchHistory",
      component: () =>
        import("@/Pages/LegalResearch/case_law_search/history.vue"),
    },
    // result showing
    {
      path: "/case_law_search/result/:id",
      name: "CaseLawSearchResult",
      component: () =>
        import("@/Pages/LegalResearch/case_law_search/result.vue"),
    },
    // detail summary for campaign id
    {
      path: "/case_law_search/result/summary/:id",
      name: "CaseLawSearchSummary",
      component: () =>
        import("@/Pages/LegalResearch/case_law_search/summary.vue"),
    },
    // LegislationSearch
    // Input fields
    {
      path: "/legislation_search",
      name: "LegislationSearch",
      component: () =>
        import("@/Pages/LegalResearch/legislation_search/main.vue"),
    },
    // history table
    {
      path: "/legislation_search/history",
      name: "LegislationSearchHistory",
      component: () =>
        import("@/Pages/LegalResearch/legislation_search/history.vue"),
    },
    // result
    {
      path: "/legislation_search/result/:id",
      name: "LegislationSearchResult",
      component: () =>
        import("@/Pages/LegalResearch/legislation_search/result.vue"),
    },
    // details summary for each campaign id
    {
      path: "/legislation_search/result/summary/:id",
      name: "LegislationSearchSummary",
      component: () =>
        import("@/Pages/LegalResearch/legislation_search/summary.vue"),
    },
  ],

  CaseLawSummarizer: [
    // the following path is not yet connected
    {
      path: "/case_law_summarizer",
      name: "CaseLawSummarizer",
      component: () => import("@/Pages/CaseSummarizer/main.vue"),
      /** Original Path
       * path: /Case_Summarizer/Judgment_File
       * name: JudgmentFile
       * comp: CaseSummarizer/JudgmentFile.vue
       */
    },
    {
      path: "/case_law_summarizer/case_:id",
      name: "CaseLawSummarizer_Case",
      component: () => import("@/Pages/CaseSummarizer/CaseSummary.vue"),
      /** Original Path
       * path: /Case_Summarizer/Case_Summary/:id
       * name: Case_Summarizer_CaseSummary
       * comp: CaseSummarizer/CaseSummary.vue
       */
    },
  ],
  LegalWriter: [
    // the following path is not yet connected
    {
      path: "/legal_compose/legal_writer/category",
      name: "LegalWriter_Category",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Writer
       * comp: LegalWriter/ChooseCategory.vue
       */
    },
    {
      path: "/legal_compose/legal_writer",
      name: "LegalWriter",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Writer/History
       * name: LegalWriterHistory
       * comp: LegalWriter/History.vue
       */
    },
    {
      path: "/legal_compose/legal_writer/create",
      name: "LegalWriter_Create",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Writer/InputSteps/:step
       * name: LegalWriterInputs
       * comp: LegalWriter/InputSteps.vue
       */
    },
    {
      path: "/legal_compose/legal_writer/campaign_:id",
      name: "LegalWriter_Campaign",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Legal_Writer/LegalWriterEdit/:id
       * name: LegalWriterEdit
       * comp: LegalWriter/EditLegalWriter.vue
       */
    },
  ],

  ContractReview: [
    // the following path is not yet connected
    {
      path: "/legal_compose/contract_review",
      name: "ContractReview",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Contract_Review/Choose_Party
       * name: ChooseParty
       * comp: ContractReview/ChooseParty.vue
       */
    },
    {
      path: "/legal_compose/contract_review/:id",
      name: "ContractReview_Discloser",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Contract_Review/:id
       * name: DiscloserPage
       * comp: ContractReview/ResultPage.vue
       */
    },
    {
      path: "/legal_compose/contract_review/:id",
      name: "ContractReview_Recipient",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Contract_Review/:id
       * name: DiscloserPage
       * comp: ContractReview/ResultPage.vue
       */
    },
    {
      path: "/legal_compose/contract_review/discloser/campaign_:id",
      name: "ContractReview_Discloser_Campaign",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Contract_Review/Contract_Document/:id
       * name: ContractDocumentDiscloser
       * comp: ContractReview/ContractDocument.vue
       */
    },
    {
      path: "/legal_compose/contract_review/recipient/campaign_:id",
      name: "ContractReview_Recipient_Campaign",
      component: () => import("@/Pages/DummyPage.vue"),
      /** Original Path
       * path: /Contract_Review/Contract_Document/:id
       * name: ContractDocumentDiscloser
       * comp: ContractReview/ContractDocument.vue
       */
    },
  ],
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      component: () => import("@/Pages/Layout.vue"),
      children: [
        {
          path: "/",
          redirect: "/overview",
        },

        // Login
        {
          path: "/login",
          name: "Login",
          component: () => import("@/Pages/Authentication/main.vue"),
          props: true,
          // name: SignIn
        },
        // Getting Start
        {
          path: "/gettingstart",
          name: "GettingStart",
          component: () => import("@/Pages/GettingStart/main.vue"),
        },
        // Overview
        {
          path: "/overview",
          name: "Overview",
          component: () => import("@/Pages/Overview/main.vue"),
        },
        {
          path: "/forgot-password/input",
          name: "ForgotPasswordInput",
          component: () => import("@/Pages/Authentication/ForgotPassword.vue"),
        },
        {
          path: "/Forgot-Password",
          name: "ForgotPasswordForm",
          component: () =>
            import("@/Pages/Authentication/ForgotPasswordForm.vue"),
        },

        ...ROUTES.Archieve,
        ...ROUTES.Bookmark,
        ...ROUTES.ExpertMode,
        ...ROUTES.LegalCopilot,
        ...ROUTES.LegalAdvice,
        ...ROUTES.LegalResearch,
        ...ROUTES.CaseLawSummarizer,
        ...ROUTES.LegalWriter,
        ...ROUTES.ContractReview,

        /* LEGACY */
        // =============================================================================
        //Authentication pages
        // =============================================================================

        //No page (Here is signUp in Background.vue it's stated as signIn)
        {
          path: "/signup",
          name: "SignUp",
          // "@/Pages/AuthenticationPages/Background.vue"
          component: () => import("@/Pages/DummyPage.vue"),
          props: true,
        },

        //EnterEmail.vue
        {
          path: "/enter_email",
          name: "enter_email",
          // "@/Pages/AuthenticationPages/Background.vue"
          component: () => import("@/Pages/DummyPage.vue"),
          props: true,
        },

        //ResetPassword.vue
        {
          path: "/Forgot-Password",
          name: "ResetPassword",
          // "@/Pages/AuthenticationPages/Background.vue"
          component: () => import("@/Pages/DummyPage.vue"),
          props: true,
        },

        //SignUp.vue
        {
          path: "/Creating-Account",
          name: "Create Account",
          // "@/Pages/AuthenticationPages/Background.vue"
          component: () => import("@/Pages/DummyPage.vue"),
        },

        //ThankYou.vue
        {
          path: "/result",
          name: "Thank_you",
          // "@/Pages/AuthenticationPages/Background.vue"
          component: () => import("@/Pages/DummyPage.vue"),
        },

        // Activity log
        {
          path: "/activity_log",
          name: "ActivityLog",
          component: () => import("@/Pages/Others/activitylog.vue"),
        },

        // =============================================================================
        //Settings
        // =============================================================================
        {
          path: "/settings/profile",
          name: "profile",
          // component: () => import("@/Pages/Settings/profile.vue"),
          component: () => import("@/Pages/Profile/account.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "profile", // hyperlink
                text: "\u2002Profile", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/billing_details",
          name: "billing_details",
          // component: () => import("@/Pages/Settings/BillingDetails"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "billing_details", // hyperlink
                text: "\u2002Billing Details", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/employees",
          name: "Employees",
          // component: () => import("@/Pages/Settings/employeesTable.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Employees", // hyperlink
                text: "\u2002Employees", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/clients",
          name: "Clients",
          // component: () => import("@/Pages/Settings/ClientsTable.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/clients/client/:id",
          name: "client",
          // component: () => import("@/Pages/Settings/ClientEdit.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients\u2002/", // crumb text
              },
              {
                name: "client", // hyperlink
                text: "\u2002Client", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/clients/add_client",
          name: "add_client",
          // component: () => import("@/Pages/Settings/AddClient.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients\u2002/", // crumb text
              },
              {
                name: "add_client", // hyperlink
                text: "\u2002Add Client", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/roles",
          name: "Roles",
          // component: () => import("@/Pages/Settings/rolesTable.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Roles", // hyperlink
                text: "\u2002Roles", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/policies",
          name: "Policies",
          // component: () => import("@/Pages/Settings/policiesTable.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Policies", // hyperlink
                text: "\u2002Policies", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/employees/employee",
          name: "employee",
          // component: () => import("@/Pages/Settings/EmployeEdit.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Employees", // hyperlink
                text: "\u2002Employees\u2002/", // crumb text
              },
              {
                name: "employee", // hyperlink
                text: "\u2002Employee", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/roles/role",
          name: "role",
          // component: () => import("@/Pages/Settings/RoleEdit.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Roles", // hyperlink
                text: "\u2002Roles\u2002/", // crumb text
              },
              {
                name: "role", // hyperlink
                text: "\u2002Role", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/policies/policy",
          name: "policy",
          // component: () => import("@/Pages/Settings/PolicyEdit.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Policies", // hyperlink
                text: "\u2002Policies\u2002/", // crumb text
              },
              {
                name: "policy", // hyperlink
                text: "\u2002Policy", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Tutorial
        // =============================================================================
        {
          path: "/tutorial",
          name: "tutorial",
          // component: () => import("@/components/Tutorial.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "tutorial", // hyperlink
                text: "\u2002Tutorial", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Subscribe Page
        // =============================================================================
        {
          path: "/pricing_subscribe/:plan_id/:plan_price_id/:period/:price",
          name: "pricing_subscribe",
          // component: () => import("@/Pages/NoPlanPages/Subscribe.vue"),
          component: () => import("@/Pages/DummyPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Pricing", // hyperlink
                text: "\u2002Pricing\u2002/", // crumb text
              },
              {
                name: "pricing_subscribe", // hyperlink
                text: "\u2002Subscribe", // crumb text
              },
            ],
          },
        },
      ],
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   if (
//     from.name === "LegalExpertSystemPreTrialResult" &&
//     from.params.category === "Trial" &&
//     to.name === "LegalExpertSystemFileInput"
//   ) {
//     next("/Legal_Copilot");
//   } else {
//     next();
//   }
// });

export default router;
