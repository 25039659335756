/* eslint-disable */
import Vue from "vue";
import { extractDisplayName } from "./store/utils";

/**
 * convert user's full name to display name
 * current output format: [ AAA BBB ] of [ AAA BBB CCC DDD ]
 * // first two section of the name, section is divided by space
 * 
 * this convertion does not cater for Pure Chinese Full Name
 */
Vue.filter("username", (val) => extractDisplayName(val))

/**
 * convert abbreviation of any Feature to the
 * general Feature Category
 */
Vue.filter("feature", (val) => {
  return {
    "les": "Legal AI Trial Copilot",
    "les_submission": "Legal AI Trial Copilot",
    "argument": "Legal Argument",
    "memo": "Legal Memo/Opinion",
    "case_summarizer": "Case Law Summarizer",
    "statement": "Case Law Search",
    "legislation": "Legislation Search",
    "LOD": "Legal Compose",
    "contract_review": "Legal Compose",
    "chatbot": "Nexa Expert Mode",
    "nexa_deep_research": "Deep Research",
    "nexa_find_precedents": "Find Precedents",
    "nexa_document_insights": "Document Insights",
    "nexa_compare_jurisdictions": "Compare Jurisdictions",
    "nexa_build_argument": "Build an Argument",
    "nexa_build_memo": "Draft a Legal Memo",
    "nexa_analyze_pleadings": "Analyze Pleadings",
  }[val] ?? `>> ${val} <<`;
})

/**
 * convert any <Date> data to a consistent display format
 * current output format:
 *  US >> [ MMM. DD YYYY ]
 *  other >> [ DD MM. YYYY ]
 */
Vue.filter("date", (val) => {
  const date = new Date(val);
  const Month = [
    "Jan.", "Feb.", "Mar.", "Apr.",
    "May", "Jun.", "Jul.", "Aug.",
    "Sep.", "Oct.", "Nov.", "Dec.",
  ][date.getMonth()];

  return "en-US" == navigator.language
    ? `${Month} ${ date.getDate() } ${ date.getFullYear() }`
    : `${ date.getDate() } ${Month} ${ date.getFullYear() }`;
})

/**
 * convert Legal Writer/<Compose Type> data from API to display category name
 * current ouput format: <compose_type> -> <Category Name>
 */
Vue.filter("legalWriter_composeType", (val) => {
  return {
    "lod_outstanding_payment": "Outstanding Payment",
    "lod_property_damage": "Property Damage Claim",
    "lod_insurance": "Insurance Claim",

    'custom': "Custom Agreement",

    "creative_media": "Creative Media",
    "others": "Other Agreements",
    "agreement_partnership": "Partnership",
    "agreement_snp": "Sales and Purchase",
    "agreement_nda": "Non-Disclosure Agreement",

    "argument": "Legal Argument",
    "memo": "Legal Research Memo and Opinion",
  }?.[val] ?? val;
})

// LEGACY

// import moment from "moment";

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  const arr = value.split(" ");
  const capitalized_array = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

// Vue.filter("lan", (value) => {
//   const typeMapping = {
//     "en": "English",
//     "tl": "Tagalog",
//     "ms": "Bahasa Malaysia",
//     "vi": "Vietnamese",
//     "zh-CN": "Chinese (Simplified)",
//     "ja": "Japanese",
//     "ar": "العربية",
//     "it": "Italian",
//     "fr": "French",
//     "de": "German",
//     "pt": "Portuguese",
//     "es": "Spanish",
//   };
//   return typeMapping
//     ?.[value]
//     ?? value;
// });

// Vue.filter("loc", (value) => {
//   if (value?.endsWith('com')) {
//     return "United States";
//   } else if (value?.endsWith('my')) {
//     return "Malaysia";
//   } else if (value?.endsWith('au')) {
//     return "Australia";
//   } else if (value?.endsWith('sg')) {
//     return "Singapore";
//   } else if (value?.endsWith('ca')) {
//     return "Canada";
//   } else if (value?.endsWith('vn')) {
//     return "Vietnam";
//   } else if (value?.endsWith('tw')) {
//     return "Taiwan";
//   } else if (value?.endsWith('id')) {
//     return "Indonesia";
//   } else if (value?.endsWith('th')) {
//     return "Thailand";
//   } else if (value?.endsWith('ae')) {
//     return "United Arab Emirates";
//   } else if (value?.endsWith('ar')) {
//     return "Argentina";
//   } else if (value?.endsWith('uk')) {
//     return "United Kingdom";
//   } else if (value?.endsWith('de')) {
//     return "Germany";
//   } else {
//     return value;
//   }
// });

Vue.filter("formatDateLoc", (value) => {
  if (value) {
    // return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale).format(new Date(value))
    return moment(String(value)).format("ll");
  }
});

Vue.filter("formatDate", (value) => {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("formatDate2", (value) => {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});

// Vue.filter("letterType", (value) => {
//   if ( value?.startsWith("creative_partnerships") ) {
//     return "Creative Partnerships";
//   } else if ( value?.startsWith('technology_ventures') ) {
//     return "Technology Ventures";
//   } else if ( value?.startsWith('international') ) {
//     return "International";
//   } else if ( value?.startsWith('real_estate_purchase') ) {
//     return "Real Estate Purchase";
//   } else if ( value?.startsWith('stock_purchase') ) {
//     return "Stock Purchase";
//   } else if ( value?.startsWith('subscription') ) {
//     return "Subscription";
//   } else if ( value?.startsWith('asset') ) {
//     return "Asset Purchase";
//   } else if (
//     value == "business_individual_individual" ||
//     value == "business_organization_individual"
//   ) {
//     // [!!]
//     return "Business Purchase";
//   } else if ( value?.startsWith('vendor') ) {
//     return "Vendor";
//   } else if (  value?.startsWith('investor_') ) {
//     return "Investor";
//   } else if ( value?.startsWith('noncompete') ) {
//     return "Non-Compete";
//   } else if ( value?.startsWith('uni_') ) {
//     return "Unilateral";
//   } else if ( value?.startsWith('mutual') ) {
//     return "Mutual";
//   } else if ( value?.startsWith('partnership_') ) {
//     return "Partnership";
//   } else if ( value?.startsWith('cm_option') ) {
//     return "Option Agreement";
//   } else if ( value?.startsWith('cm_development') ) {
//     return "Development Agreement";
//   } else if ( value?.startsWith('cm_production') ) {
//     return "Production Agreement";
//   } else if ( value?.startsWith('cm_license') ) {
//     return "License Agreement";
//   } else if ( value?.startsWith('cm_talent') ) {
//     return "Talent Agreement";
//   } else if ( value?.startsWith('cm_syndication') ) {
//     return "Syndication Agreement";
//   } else if ( value?.startsWith('distribution') ) {
//     return "Distribution Agreement";
//   } else if ( value?.startsWith('cm_producer_distributor') ) {
//     return "Pro-Producer Distributor Agreement";
//   } else if ( value?.startsWith('cm_film_production') ) {
//     return "Film Production Agreement";
//   } else if ( value?.startsWith('cm_loan') ) {
//     return "Loan of Art Agreement";
//   } else if ( value?.startsWith('cm_writer') ) {
//     return "Screenplay Writer Commission Agreement";
//   } else if ( value?.startsWith('ipa_agreement') ) {
//     return 'Intellectual Property Agreement';
//   } else if ( value?.startsWith('franchise') ) {
//     return 'Franchise Agreement';
//   } else if ( value?.startsWith('manufacturing') ) {
//     return 'Manufacturing Agreement';
//   } else if ( value?.startsWith('consignment') ) {
//     return 'Consignment Agreement';
//   } else if ( value?.startsWith('independant_constructor_') ) { // typo!
//     return 'Independent Constructor Agreement';
//   } else if ( value?.startsWith('construction_acceleration_') ) {
//     return 'Construction Contract Acceleration Agreement';
//   } else if ( value?.startsWith('sales_agency_') ) {
//     return 'Sales Agency Agreement';
//   } else if ( value?.startsWith('web_design') ) {
//     return 'Web Design Agreement';
//   } else if ( value?.startsWith('payment') ) {
//     return 'Payment Agreement';
//   } else if ( value?.startsWith('sponsorship') ) {
//     return 'Sponsorship Agreement';
//   }

//   const typeMapping = {
//     "custom": "Custom Agreement",
//     "individual_individual": "Individual - Individual",
//     "individual_organization": "Individual - Organization",
//     "organization_individual": "Organization - Individual",
//     "auto_insurance_claims": "Auto Insurance Claims",
//     "homeowners_insurance_claims": "Homeowner's Insurance Claims",
//     "health_insurance_claims": "Health Insurance Claims",
//     "life_insurance_claims": "Life Insurance Claims",
//     "disability_insurance_claims": "Disability Insurance Claims",
//     "travel_insurance_claims": "Travel Insurance Claims",

//     "small_businesses": "Small Businesses",
//     "join_ventures": "Join Ventures",
//     "real_estate_ventures_individual": "Real Estate Ventures",
//     "employer": "Employer",
//     "consultant": "Consultant",
//     "board_organization_individual": "Board Members",
//   };
//   return typeMapping
//     ?.[value]
//     ?? value;
// });